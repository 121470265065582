<template>
  <div class="row">
    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1 col-12">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              @change="searchProduct"
              v-model="filters.q"
              :placeholder="$t('Base.Placeholder.search_product')"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section class="grid-view px-1">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link
            :to="{ path: '/shop/info', query: { id: product.product_id } }"
          >
            <b-img
              :alt="`${product.title}-${product.id}`"
              fluid
              class="card-img-top"
              :src="product.image"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body class="position-relative">
          <span
            class="position-absolute p-2"
            style="top: 0; left: 0"
            v-if="product.show_timer"
          >
            <countdown
              :end-time="new Date(product.end_time).getTime()"
              v-if="product.price"
            >
              <template v-slot:process="anyYouWantedScopName">
                <b-badge variant="light-danger">{{
                  $t("Pages.Shop.Title.until_end_discount")
                }}</b-badge>
                <b-badge variant="light-warning">
                  <span v-if="anyYouWantedScopName.timeObj.d != 0">{{
                    `${anyYouWantedScopName.timeObj.d}` + $t("Pages.Shop.Title.Day") + " "
                  }}</span>
                  <span v-if="anyYouWantedScopName.timeObj.h != 0">{{
                    `${anyYouWantedScopName.timeObj.h}` + $t("Pages.Shop.Title.Hour") + " "
                  }}</span>

                  <span v-if="anyYouWantedScopName.timeObj.m != 0">{{
                    `${anyYouWantedScopName.timeObj.m}` + $t("Pages.Shop.Title.Minute") + " "
                  }}</span>

                  <span v-if="anyYouWantedScopName.timeObj.s != 0">{{
                    `${anyYouWantedScopName.timeObj.s}` + $t("Pages.Shop.Title.Second") + " "
                  }}</span>
                </b-badge>
              </template>
            </countdown>
          </span>
          <div class="item-wrapper">
            <div>
              <h6 class="item-price" v-if="product.show_discount">
                <s class="text-danger"
                  >{{ product.original_price.toLocaleString() }}
                  {{ $t("Pages.Shop.Title.Tooman") }}</s
                >
              </h6>
              <h6 class="item-price" v-if="product.price">
                {{ product.price.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
              </h6>
              <h6 class="item-price" v-else>
                {{ product.original_price.toLocaleString() }} {{ $t("Pages.Shop.Title.Tooman") }}
                <br />
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ path: '/shop/info', query: { id: product.product_id } }"
            >
              {{ product.title }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25">
                {{ product.name }}
              </b-link>
            </b-card-text>
          </h6>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">{{ product.price }} {{ $t("Pages.Shop.Title.Tooman") }}</h4>
            </div>
          </div>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            v-if="product.active != 0"
            :to="{ path: '/shop/info', query: { id: product.product_id } }"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{ $t("Buy") }}</span>
          </b-button>
          <b-button
            variant="danger"
            tag="a"
            class="btn-cart"
            v-else
            :to="{ path: '/shop/info', query: { id: product.product_id } }"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{ $t("Pages.Shop.Title.ComingSoon") }}</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <section v-if="pages > 1">
      <b-row>
        <b-col cols="12">
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="pages"
            use-router
            class="mb-0"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPaginationNav,
  BInputGroupPrepend,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPaginationNav,
    BInputGroupPrepend,
    BBadge,
  },
  data() {
    return {
      mqShallShowLeftSidebar: false,
      filters: {
        q: "",
        categories: [],
      },
      filterOptions: {
        categories: [],
      },
      pages: 0,
      products: [],
    };
  },
  created() {
    this.getProducts();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getNewData();
      }
    },
  },
  methods: {
    searchProduct(arg) {
      if (arg) {
        this.$http
          .get(`/api/shop/product/list/search/${arg}`)
          .then((response) => {
            this.products = response.data.data;
            this.pages = response.data.last_page;
          });
      } else {
        this.getProducts();
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getProducts() {
      this.$http.get("/api/shop/product/list").then((response) => {
        this.products = response.data.data;
        this.pages = response.data.last_page;
      });
    },
    getNewData() {
      this.$http
        .get("/api/shop/product/list", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((response) => {
          this.products = response.data.data;
          this.pages = response.data.last_page;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";

[dir="rtl"] body .content-detached.content-right .content-body {
  margin-right: 0 !important;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.item-img a {
  width: 100% !important;
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
